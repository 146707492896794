import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, datas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        if (search.date) {
          const rowItem = [
            { text: vm.$t('globalTrans.date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
          const rowItem = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? vm.searchHeaderData.fiscal_year_bn : vm.searchHeaderData.fiscal_year_en, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (search.circular_memo_no) ? (i18n.locale === 'bn') ? vm.search.circular_memo_no : vm.search.circular_memo_no : '', style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
          const rowItem1 = [
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? vm.searchHeaderData.training_title_bn : vm.searchHeaderData.training_title_en, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.batch_no'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (search.batch_no) ? (i18n.locale === 'bn') ? vm.searchHeaderData.batch_bn : vm.searchHeaderData.batch_en : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem1)
        // if (search.training_title_id) {
        //   const rowItem = [
        //     { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'right' },
        //     { text: ':', style: 'td', alignment: 'center' },
        //     { text: (i18n.locale === 'bn') ? vm.searchHeaderData.training_title_bn : vm.searchHeaderData.training_title_en, style: 'td', alignment: 'left' }
        //   ]
        //   allRowsHead.push(rowItem)
        // }
        // if (search.circular_memo_no) {
        //   const rowItem = [
        //     { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'right' },
        //     { text: ':', style: 'td', alignment: 'center' },
        //     { text: (i18n.locale === 'bn') ? search.circular_memo_no : search.circular_memo_no, style: 'td', alignment: 'left' }
        //   ]
        //   allRowsHead.push(rowItem)
        // }
        // if (search.batch_no) {
        //   const rowItem = [
        //     { text: vm.$t('elearning_iabm.batch_no'), style: 'td', alignment: 'right' },
        //     { text: ':', style: 'td', alignment: 'center' },
        //     { text: (i18n.locale === 'bn') ? vm.searchHeaderData.batch_bn : vm.searchHeaderData.batch_en, style: 'td', alignment: 'left' }
        //   ]
        //   allRowsHead.push(rowItem)
        // }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['15%', '2%', '30%', '15%', '2%', '30%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
            { text: vm.$t('elearning_tpm.work_place'), style: 'th', alignment: 'center' },
            // { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' },
            // { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'center' },
            { text: vm.$t('globalTrans.number_of_days'), style: 'th', alignment: 'center' },
            { text: vm.$t('elearning_tim.daily_honor'), style: 'th', alignment: 'center' },
            { text: vm.$t('elearning_tim.total_honor'), style: 'th', alignment: 'center' },
            { text: vm.$t('elearning_tim.other_detuct'), style: 'th', alignment: 'center' },
            { text: vm.$t('elearning_tim.total_tk'), style: 'th', alignment: 'center' }
          ]
        ]
        datas.filter((data, optionIndex) => {
          let professionType = ''
          if (data.p_profession_type === '1') {
            if (data.not_here_office === 1) {
              professionType = ((vm.$i18n.locale === 'en') ? data.p_office_name : data.p_office_name_bn)
            }
            if (data.not_here_office === 0) {
              professionType = (vm.getOfficeName(data.p_office_id)) + ', ' + (vm.getOrgName(data.p_org_id))
            }
          } else {
            professionType = vm.$i18n.locale === 'en' ? data.p_office_name : data.p_office_name_bn
          }
          allRows.push(
            [
              { text: vm.$n(optionIndex + 1), style: 'tdd', alignment: 'center' },
              { text: (vm.$i18n.locale === 'bn' ? data.name_bn : data.name), style: 'tdd', alignment: 'center' },
              { text: (vm.$i18n.locale === 'en') ? data.p_designation_en : data.p_designation_bn, style: 'tdd', alignment: 'center' },
              { text: professionType, style: 'tdd', alignment: 'center' },
              // { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td', alignment: 'center' },
              // { text: (data.personal.email), style: 'td', alignment: 'center' },
              { text: vm.$n(data.total_days), style: 'tdd', alignment: 'center' },
              { text: vm.$n(data.honour_amount), style: 'tdd', alignment: 'center' },
              { text: vm.$n(data.total_days * data.honour_amount), style: 'tdd', alignment: 'center' },
              { text: vm.$n(data.other), style: 'tdd', alignment: 'center' },
              { text: vm.$n(data.payment_amount), style: 'tdd', alignment: 'center' }
            ]
          )
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['7%', '9%', '14%', '25%', '9%', '10%', '8%', '8%', '8%'],
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        if (i18n.locale === 'en') {
          pdfContent.push({
            text: [
                vm.$t('tpm_report.report_footer1') + ' ',
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        if (i18n.locale === 'bn') {
          pdfContent.push({
            text: [
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer1') + ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        footer: function (currentPage, pageCount) {
          return [
            {
              canvas: [
                {
                  type: 'line',
                  x1: 20,
                  y1: 0,
                  x2: 595 - 20,
                  y2: 0, // Adjust x2 to the page width minus margin
                  lineWidth: 0.5,
                  lineColor: 'black'
                }
              ]
            },
            {
              columns: [
                {
                  text: vm.$t('tpm_report.page') + ' ' + vm.$n(currentPage.toString()) + ' / ' + vm.$n(pageCount),
                  alignment: 'left',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.idsdp'),
                  alignment: 'center',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.footer_date_time') + ' ' + new Date().toLocaleDateString('bn-BD') + ', ' + new Date().toLocaleTimeString('bn-BD', { hour12: false }),
                  alignment: 'right',
                  style: 'footerStyle'
                }
              ],
              margin: [10, 5] // Adjust the margin as needed
            }
          ]
        },
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          footerStyle: {
            fontSize: 8,
            margin: [20, 5, 20, 5]
          },
          th: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
        //   address: {
        //     fontSize: 9,
        //     margin: [0, -10, 0, 0]
        //   },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
